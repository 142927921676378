.video_volume_div {
    z-index: 902;
    background-color: #eee;
    color: #fff;
    height: 6px;
    width: min(calc(100vw - 32px), 400px);
    margin: 16px 0;
}

.video_volume_bar_div {
    width: 0;
    background: linear-gradient(to right, #81bd56, #81bd56 200px, #cc0000);
    height: 6px;
}
