
@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
	font-family: 'Garnett Medium';
    src: url('/assets/fonts/Garnett-Medium.ttf');
	font-style: medium;
}

@font-face {
	font-family: 'Garnett Regular';
    src: url('/assets/fonts/Garnett-Regular.ttf');
	font-style: normal;
}

@font-face {
	font-family: 'Garnett Semibold';
    src: url('/assets/fonts/Garnett-Semibold.ttf');
	font-style: normal;
}


@font-face {
	font-family: 'Garnett Regular Italic';
    src: url('/assets/fonts/Garnett-RegularItalic.ttf');
    font-family: 'Garnett';
	font-style: italic;
}

h1 {
	font-size: 40px;
	font-weight: 600;
	line-height: 56px;
}

h2 {
	font-size: 25px;
	font-weight: 600;
	line-height: 37px;
}

h3 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
}

h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
}

p {
	font-size: 16px;
	line-height: 24px;
}

b {
	font-weight: 600;
}

p.smaller {
	font-size: 14px;
	line-height: 22px;
}

.r-fontsize {
	font-size: 16px;
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 30px;
		line-height: 40px;
	}

	h2 {
		font-size: 22px;
		line-height: 33px;
	}
	.r-fontsize {
		font-size: 14px;
	}
}

/* variables */
:root {
	--ion-background-color: #1a1a3a;
	--ion-text-color: #ffffff;
	--green: #d5f2a6;
	--dark_green: #009900;
	--cerise: #f61ec7;
	--dark_blue: #000026;
	--medium_blue: #1a1a3a;
	--light_blue: #e8efff;
	--lighter_blue: #f2f5ff;
	--blue: #98b1ff;
	--blue_sky: #94ABFF;
	--blue_gray: #60608b;
	--border_blue: #42425f;
	--gray: #aaa;
	--dark_gray: #4c4c67;
	--purple: #bc80f0;
	--light_gray: #efeef1;
	--red: #c00;
	--light_red: #fcc;
	--pink: #f97db6;
	--box_shadow: 2px 4px 10px 0px #ccc;
	--error_red: #ff405e;
	--area0: #BC80F0;
	--area1: #60b9a5;
	--area2: #5B6EF4;
	--area3: #EAF47F;
	--area4: #FF00C7;
	--neutral_400: #B2B2BE;
	--neutral_600: #4C4C67;
	--neutral_700: #20203d;
	--neutral_800: #19193C;
	--neutral_900: #000026;
	--primary_500: #94ABFF;

}

/* Standard selectors */

body {
	font-family: "Garnett", "sans-serif";
	padding: 0;
	margin: 0;
}

ul li {
	/*line-height: 22px;*/
	padding-bottom: 6px;
}

ion-segment {
	border-radius: 5px;
	height: 38px;
}

ion-segment {
	--background: var(--blue_gray);
}

ion-segment-button.ios {
	--padding-start: 34px;
	--padding-end: 34px;
	font-size: 16px;
	color: #000;
}

ion-segment-button::part(indicator-background) {
	border-radius: 5px;
}


/* New Colors */ 
.color_neutral_400 {
	color: var(--neutral_400);
}

.color_neutral_900 {
	color: var(--neutral_900);
}


.bg_neutral_400 {
	background-color: var(--neutral_400);
}

.bg_neutral_600 {
	background-color: var(--neutral_600);
}

.bg_neutral_700 {
	background-color: var(--neutral_700);
}

.bg_neutral_800 {
	background-color: var(--neutral_800);
}

.bg_neutral_900 {
	background-color: var(--neutral_900);
}

.bg_primary_500 {
	background-color: var(--primary_500);
}

/* Colors */
.color_purple {
	color: var(--ion-color-purple);
}

.color_cerise {
	color: var(--cerise);
}

.color_area0 {
	color: var(--area0);
}

.color_area1 {
	color: var(--area1);
}

.color_area2 {
	color: var(--area2);
}

.color_area3 {
	color: var(--area3);
}

.color_area4 {
	color: var(--area4);
}

.bg_apricot {
	background-color: var(--ion-color-apricot);
}

.bg_orange {
	background-color: var(--ion-color-orange);
}

.bg_pink {
	background-color: var(--pink);
}

.green {
	color: var(--green);
}

.bg_green {
	background-color: var(--green);
}

.bg_dark_gray {
	background-color: var(--dark_gray);
}

.error_red {
	color: var(--error_red);
}

.red {
	color: var(--red);
}

.bg_red {
	background-color: var(--red);
}

.blue {
	color: var(--blue);
}

.bg_blue {
	background-color: var(--blue);
}

.blue_sky {
	color: var(--blue_sky);
}



.bg_blue_gray {
	background-color: var(--blue_gray);
}

.border_blue_gray {
	border: 1px solid var(--blue_gray);
}

.bg_medium_blue {
	background-color: var(--medium_blue);
}

.bg_green {
	background-color: var(--green);
}

.dark_green {
	color: var(--dark_green);
}

.bg_dark_green {
	background-color: var(--dark_green);
}

.gray {
	color: var(--gray);
}

.bg_gray {
	background-color: var(--gray);
}

.bg_light_gray {
	background-color: var(--light_gray);
}

.bg_purple {
	background-color: var(--purple);
}

.bg_white {
	background-color: #fff;
}

.white {
	color: #fff;
}

.dark_blue {
	color: var(--dark_blue);
}

.bg_dark_blue {
	background-color: var(--dark_blue);
}

.bg_border_blue {
	background-color: var(--border_blue);
}

.bg_light_blue {
	background-color: var(--light_blue);
}

.bg_lighter_blue {
	background-color: var(--lighter_blue);
}

.bg_light_red {
	background-color: var(--light_red);
}

ion-content.app_bg {
	--background: var(--medium_blue);
}

.text_underline {
	text-decoration: underline;
}

/* Containers */
.inline-flex {
	display: inline-flex;
}
.header_navigation {
	display: flex;
	justify-content: space-between;
	background-color: var(--dark_blue);
	color: #fff;
	align-items: center;
	padding: 16px 32px 16px 124px;
}

.page_content {
	margin: 80px 0 0 102px;
	border-radius: 12px;
	padding-left: 20px;
	padding-right: 32px;
	padding-bottom: 32px;
	width: calc(100vw - 118px);
	min-width: 300px;
	background-color: var(--medium_blue);
}

.h20 {
	height: 20px;
}

.page_content.onboarding {
	margin: 107px 0 0 102px;
}

.page_section {
	background-color: var(--neutral_900);
	border-radius: 5px;
	max-width: 1296px;
	padding: 40px;
}

.tab_page_section {
    background-color: var(--neutral_900);
    border-radius: 5px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    max-width: 1296px;
    padding: 40px;
    padding-top: 0;
  }

.page_section.no_width {
	max-width: unset;
}

.page_section.no_bg {
	background-color: unset;
	padding: 0px 40px 0px 40px;
}

.page_section_gradient {
	background: linear-gradient(159deg, rgba(37,52,59,1) 0%, rgba(0,0,38,1) 50%, rgba(37,52,59,1) 100%);
}

.gradient_pink_purple {
	background: radial-gradient(102.47% 117060.67% at 0% 100%, #FFCCC8 0%, #BC80F0 100%);
}

.top_margin {
	margin-top: 20px;
}
	
.btn_top_margin {
	margin-top: 40px;
}

.double_top_margin {
	margin-top: 40px;
}

.right_margin {
	margin-right: 20px;
}

.left_margin {
	margin-left: 20px;
}

.bottom_margin {
	margin-bottom: 20px;
}

.page_section.half {
	width: calc(50% - 10px);
	max-width: 638px;
}

.page_section.min_width {
	min-width: min-content;
}

.page_section.third {
	width: calc(33.3% - 10px);
	max-width: 421px;
}

.page_section.quarter {
	width: calc(25% - 10px);
	max-width: 309px;
}

.page_content.p-h-full {
	height: calc(100vh - 100px);
}

.page_bar {
	height: 1px;
	width: 100%;
	background-color: #60608B;
	margin: 12px 0;
}

.page_bar.no_margin {
	margin: unset;
}

.page_half_content {
	max-width: 500px;
	width: 49%;
}

.page_bar.white {
	border: #fff 1px solid;
	background-color: #fff;
}

.page_input {
	max-width: 495px;
	height: 50px;
	border-radius: 5px;
	padding-left: 16px;
	width: 100%;
	/*background-color: var(--dark_blue);*/
	background: transparent;
	border: 1px solid var(--border_blue);
	color: #fff;
}

.page_input::placeholder {
	/*color: #cfd5ea;*/
	opacity: 0.7;
}

.page_input.small {
	max-width: 80px;
	height: 29px;
}

.page_input.dark_blue {
	border: 1px solid #000026;
	color: #000026;
}

.page_input.bg_blue {
	background-color: var(--dark_blue);
}

.date_input {
	max-width: 195px;
	height: 50px;
	width: 100%;
	background-color: var(--dark_blue);
	color: #fff;
	padding-left: 16px;
	border: 1px solid #fff;
	border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-image: url('../../public/assets/icon/white/calendar.svg');
	padding-right: 20px;
	cursor: pointer;
}

.radio_input {
	display: inline-block;
	width: 25px;
	height: 25px;
	background-color: var(--dark_blue);
	border: 1px solid #fff;
	margin-right: 5px;
	border-radius: 5px;
}
.radio_input.chosen {
	background-color: var(--blue);
	border: 1px solid var(--blue);
}

.page_text_area {
	max-width: 495px;
	width: 100%;
	height: 265px;
	border-radius: 5px;
	border: 1px solid #fff;
	padding: 10px;
	background-color: var(--dark_blue);
	color: #fff;
}

.page_text_area.grey {
	border: #60608B 1px solid;
}


.page_text_area.white {
	background-color: #fff;
	color: var(--dark_blue);
}

.page_text_area.small {
	padding: 10px 0 0 10px;
	height: unset;
}

.page_text_area.medium {
	height: 99px;
	font-size: 14px;
}

.page_text_area.large {
	max-width: 804px;
	height: 155px;
}

.page_text_area.xl {
	height: 200px;
}

.page_text_area.full {
	min-width: 100%;
}

.button_disabled {
	opacity: 0.5;
	pointer-events: none;
}

.opacity_05 {
	opacity: 0.5;
}

.half_page_section {
	width: 50%;
	max-width: 638px;
}

textarea {
	background-color: var(--dark_blue);
	color: #fff;
}

textarea:focus,
input:focus {
	color: #fff;
	outline: none;
}

.page_select {
	max-width: 495px;
	height: 50px;
	border-radius: 5px;
	padding-left: 16px;
	background-color: var(--dark_blue);
	border: 1px solid #fff;
	color: #fff;
	-webkit-appearance: none;
    -moz-appearance: none;
	appearance: none;
	background-image: url('../../public/assets/icon/white/arrow_down.svg');
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: calc(100% - 8px);
	background-size: 18px;
	padding-right: 30px;
}

.page_select.w100 {
	width: 100%;
}

.page_select:focus {
	outline: none;
}

.page_half_content.full_on_small {
	min-width: 100%;
}

.page_wider_content {
	width: 100%;
	max-width: 681px;
}

.r_left_margin {
	margin-left: 20px;
}

.r_right_margin {
	margin-right: 20px;
}

.r_left_margin_early_break {
	margin-left: 20px;
}

.r_top_margin {
	margin-top: 0px;
}

.top_left_margin {
	margin: 20px 0 0 20px;
}

.picture_small {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.coach_picture {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}

.coach_picture.small {
	width: 140px;
	height: auto;
	border-radius: 50%;
}

.round {
	border-radius: 50%;
}

.r-flex {
	display: flex;
}

.r-half-col {
	width: calc(50vw - 92px);
}

.r-large-flex {
	display: flex;
}

.check_box {
	width: 32px;
	height: 32px;
	padding: 2px;
	border: 1px solid #fff;
	border-radius: 0 4px;
	display: flex;
	align-items: center;
	justify-items: center;
}

.check_box.small {
	width: 20px;
	height: 20px;
}

.error_box {
	border: 1px solid var(--error_red);
	padding: 8px;
	color: var(--error_red);
}

.text_max_width {
	max-width: 640px;
}

.bar_background {
	width: calc(100% - 20px);
	height: 30px;
	border-radius: 15px;
	background-color: #20203D;
	position: relative;
}

.bar_background.medium {
	height: 20px;
}

.bar_background.small {
	height: 16px;
	border-radius: 8px;
}

.bar_desired_value {
	margin-top: 0px;
	background-color: #60608B;
	height: 30px;
	border-radius: 15px;
	/* position: absolute; */
	z-index: 0;
}

.bar_desired_value.small {
	height: 16px;
}

.col-1_2 {
	width: calc(50% - 10px);
	display: inline-block;
	vertical-align: top;
}

.col-1_3 {
	width: calc(33% - 0.67rem);
	display: inline-block;
	vertical-align: top;
}

.bar_animated {
	height: 30px;
	width: 0;
	position: absolute;
	z-index: 5;
	/*border-top-left-radius: 15px;*/
	/*border-bottom-left-radius: 15px;*/
	border-radius: 15px;
	transition: width 1s;
}

.bar_animated.medium {
	height: 20px;
}

.bar_animated.small {
	height: 16px;
	border-radius: 8px;
	/*border-top-left-radius: 8px;*/
	/*border-bottom-left-radius: 8px;*/
}

.transition_linear_width_2s {
	transition: width 2s;
	transition-timing-function: linear;
}

.transition_linear_width_3s {
	transition: width 3s;
	transition-timing-function: linear;
}

.transition_linear_width_4s {
	transition: width 4s;
	transition-timing-function: linear;
}

.message_box {
	border: 1px solid var(--green);
	padding: 8px;
}

.select_box {
	height: 28px;
	min-width: 28px;
	border: 1px solid var(--blue);
	display: inline-block;
	vertical-align: middle;
	border-radius: 0 4px;
	color: var(--dark_blue);
}

.select_box.selected {
	background-color: var(--blue);
}

/* modifiers */
a {
	text-decoration: none;
}

.capitlize::first-letter{
	text-transform: uppercase;
}

.box_radius {
	border-radius: 8px;
}

.box_radius_small {
	border-radius: 5px;
}

.box_radius_large {
	border-radius: 16px;
}

.border_box {
	border: 1px solid var(--neutral_600);
	border-radius: 5px;
	padding: 20px;
}

.border_box.max_width {
	max-width: 696px;
}

.border_box.no_padding {
	padding: 0 20px 20px 0;
}

.icon_small {
	width: 20px;
	height: 20px;
}

.icon_medium_small {
	width: 25px;
	height: 25px;
}

.icon_medium {
	width: 30px;
	height: 30px;
}

.icon_large {
	width: 40px;
	height: 40px;
}

.icon_huge {
	width: 64px;
	height: 64px;
}

.arrow_blue {
	height: 28px;
}

.bubble_container {
	position: absolute;
	left: 15px;
	z-index: 1001;
	width: 280px;
}

.blue_bubble {
	padding: 16px;
	background-color: var(--blue);
	color: #fff;
	box-shadow: var(--box_shadow);
}

.arrow_left_blue {
	width: 0;
	height: 0;
	border-top: 16px solid transparent;
	margin-right: auto;
	border-left: 16px solid var(--blue);
}

.font_small {
	font-size: 14px;
}

.font_smaller {
	font-size: 12px;
}

.font_smallest {
	font-size: 10px;
}

.max_w_desktop {
	width: 1296px;
	max-width: calc(100vw - 250px);
}

.full_w_desktop {
	width: calc(100vw - 316px);
}

.hide_large {
	display: none;
}

.hide_medium {
	display: block;
}

.hide_small {
	display: block;
}

.show_small {
	display: none;
}

/* elements */
.link_text {
	text-decoration: underline;
	cursor: pointer;
	color: #fff;
}

.link_text:hover {
	color: var(--blue);
}

.text_hover:hover {
	color: var(--blue);
	cursor: pointer;
}

.section_button {
	border: 1px solid #fff;
	border-radius: 5px;
	width: 100%;
	max-width: 495px;
	height: 60px;
}

.section_button:hover {
	border: 1px solid #e7eeff;
	background-color: #e7eeff;
	color: var(--dark_blue);
}

.corner_radius {
	border-radius: 0 8px;
}

.border_radius_small {
	border-radius: 2px;
}

.button {
	border-radius: 0 8px;
	border: 1px solid var(--blue);
	padding: 12px 14px;
	background-color: var(--blue);
	color: var(--dark_blue);
	font-size: 14px;
	text-align: center;
	white-space: nowrap;
	min-width: 80px;
}

.button.small {
	font-size: 10px;
	padding: 10px 12px;
}

.button:hover {
	border-radius: 0 8px;
	border: 1px solid #e7eeff;
	/*padding: 15px 20px;*/
	background-color: #fff;
	color: var(--dark_blue);
	cursor: pointer;
	flex-wrap: nowrap;
}

.button.pink {
	background-color: #FFCCC8;
	color: var(--dark_blue);
	border: 1px solid var(--dark_blue);
}

.button.pink:hover {
	background-color: var(--dark_blue);
	color: #fff;
	border: 1px solid var(--dark_blue);
}

.button.red {
	background-color: var(--error_red);
	color: #fff;
	border: 1px solid var(--error_red);
}

.button.red:hover {
	background-color: #ffc4ce;
	border: 1px solid #ffc4ce;
	color: var(--dark_blue);
}

.button.white {
	/*background-color: var(--dark_blue);*/
	background: transparent;
	border: 1px solid #fff;
	color: #fff;
}

.button.white:hover {
	color: var(--dark_blue);
	background-color: #fff;
}

.button.black {
	background: transparent;
	border: 1px solid #000;
	color: #000;
}

.button.black:hover {
	color: var(--dark_blue);
	background-color: #fff;
}

.button.green {
	background-color: var(--green);
	border: 1px solid var(--green);
	color: var(--dark_blue);
}

.button.green:hover {
	background-color: #ecf8dd;
	border: 1px solid #ecf8dd;
}

.button.area0 {
	background-color: var(--area0);
	border: 1px solid var(--area0);
	color: #ffffff;
}

.button.area1 {
	background-color: var(--area1);
	border: 1px solid var(--area1);
	color: #ffffff;
}

.button.area2 {
	background-color: var(--area2);
	border: 1px solid var(--area2);
	color: #ffffff;
}

.button.area3 {
	background-color: var(--area3);
	border: 1px solid var(--area3);
	color: #000;
}

.button.area4 {
	background-color: var(--area4);
	border: 1px solid var(--area4);
	color: #fff;
}


.border_black {
	border: 1px solid #000;
}

.border_blue {
	border: 2px solid var(--blue);
}

.blue_bubble {
	padding: 16px;
	background-color: var(--blue);
	color: #fff;
	box-shadow: var(--box_shadow);
}

.circle_smaller {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.circle_small {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.circle_medium {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.circle_medium_large {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.circle_large {
	width: 60px;
	height: 60px;
	border-radius: 50%;
}


.close_div {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

.box_shadow {
	box-shadow: 0 5px 15px 0 #ddd;
}

.scroll_y {
	overflow-y: scroll;
}

.scroll_y::-webkit-scrollbar {
	display: none;
}

.min-w-200 {
	min-width: 200px;
}

.header_progress_container {
	position: fixed;
	top: 36px;
	left: min(500px, 33vw);
	height: 8px;
	width: min(520px, 36vw);
	z-index: 3001;
}

.header_progress_bar {
	height: 8px;
	border-radius: 4px;
}

.min-w-360 {
	min-width: 360px;
}

.chat_coach {
	background-color: var(--neutral_800);
}

.chat_coach:hover {
	background-color: var(--neutral_600);
}

.archived_coach_chat {
	border: 1px solid var(--neutral_600);
	background-color: transparent;
}
.archived_coach_chat:hover {
	border: 1px solid #fff;
	background-color: transparent;
}

.ai_earlier_chat_active {
	background-color: var(--blue);
	color: #000;
}

.ai_earlier_chat:hover {
	background-color: var(--neutral_600);
}

/* Initially, the icon is hidden */
.hoverable-session .hidden-on-start {
	display: none;
}

/* When hovering over the parent div, show the icon */
.hoverable-session:hover .hidden-on-start {
	display: inline-block;
}

.hoverable-session:hover:not(.ai_earlier_chat_active) .trash-icon-path {
	fill: white;
}

/* Selected should have a black fill, overriding hover */
.ai_earlier_chat_active .trash-icon-path {
	fill: black; /* Or whatever your "selected" color is */
}
.chat_msg {
	color: #fff;
	padding: 8px 14px;
	max-width: 585px;
	text-align: left;
	border-radius: 5px;
	display: inline-block;
}

.chat_msg.wide {
	max-width: 660px;
}

.chat_chosen {
	background-color: #efefef;
	color: var(--dark_blue);
}

.chat_msg.you {
	margin-left: auto;
	color: #fff;
	background-color: #20203d;
}

.chat_msg.other {
	margin-right: auto;
	background-color: #4c4c67;
}

.chat_waiting {
	margin-right: auto;
	background-color: #4c4c67;
	color: #fff;
	border-radius: 5px;
}

.rounded {
	--border-radius: 10px;

}

.dot-container {
	display: flex;
	align-items: center; /* Centers vertically */
	justify-content: center; /* Centers horizontally, if needed */
	padding: 10px;
}

.dot {
	background-color: gray;
	border-radius: 50%;
	width: 5px;
	height: 5px;
	margin: 0 2px;
	display: inline-block;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	vertical-align: middle; /* Centers dots vertically */
}

.dot1 {
	animation-name: dot1Animation;
}

.dot2 {
	animation-name: dot2Animation;
	animation-delay: 0.5s;
}

.dot3 {
	animation-name: dot3Animation;
	animation-delay: 1s;
}

@keyframes dot1Animation {
	0%, 100% {
		background-color: gray;
	}
	50% {
		background-color: white;
	}
}

@keyframes dot2Animation {
	0%, 100% {
		background-color: gray;
	}
	50% {
		background-color: white;
	}
}

@keyframes dot3Animation {
	0%, 100% {
		background-color: gray;
	}
	50% {
		background-color: white;
	}
}

ion-modal#custom-modal {
	--width: fit-content;
	--min-width: 250px;
	--height: fit-content;
	padding: 30px;
}
  
  
ion-modal#custom-modal #ion-react-wrapper {
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 80px;
	margin-left: 80px;
}


@media only screen and (max-width: 600px) {
    ion-modal#custom-modal #ion-react-wrapper {
        margin-right: 20px;
		margin-left: 20px;
    }
}

.modal_small {
	--height: 400px;
	--width: 300px;
}

.modal_medium {
	position: fixed;
	top: calc(var(--ion-safe-area-top, 0) + 22px);
	--min-height: 605px;
	--height: 60%;
	--width: 95%;
	--border-radius: 10px;
	overflow-y: scroll;
}

.modal_md {
	--height: 610px;
	--width: 598px;
	--border-radius: 5px;
}

.modal_md.narrow {
	--width: 375px;
}

.modal_centered {
	--max-height: 70vh;
	--width: calc(100vw - 32px);
	--border-radius: 10px;
}

.modal_full {
	--max-height: 100vh;
	--max-width: 100vw;
	--width: 100vw;
	--height: 100vh;
}

.modal_r-full {
	--min-height: 90vh;
	--width: calc(100vw - 32px);
	--max-width: 1000px;
	--border-radius: 10px;
}

.modal_tall {
	--min-height: 90vh;
	--width: calc(100vw - 32px);
	--max-width: 600px;
	--border-radius: 10px;
}

.modal_backdrop {
	--backdrop-opacity: 0.2;
}

.onboarding_modal {
	max-height: 100vh;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
	transition: all 0.1s linear;
}

.onboarding_modal_content {
	width: calc(100% - 170px);
	max-width: 1296px;
	height: 100%;
	max-height: 913px;
	background-color: rgba(0,0,38,0.8);
	margin-left: 122px;
	margin-top: 102px;
	padding: 275px 10px;
	transition: all 0.1s linear;
}

@keyframes pulsing {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.animate_pulse {
	animation: pulsing 2s forwards infinite;
}

.animate_toggle {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s;
}

.animate_toggle.open {
	max-height: 800px;
}

.r-ml-4 {
	margin-left: 2rem;
}

.r-ml-8 {
	margin-left: 2rem;
}

.r-ml-12 {
	margin-left: 3rem;
}

.extra_large_spacing {
	margin: 85px 0;
}

.extra_large_padding {
	padding: 64px 43px 40px 79px;
}

.mobile_mt-12 {
	margin-top: 0;
}


.badge {
	background-color: var(--dark_blue);
	color: #fff;
	padding: 4px 8px;
	border-radius: 5px;
	display: inline-block;
}

/* SVG icon colors */
.svg_black {
 filter: none;
}

.svg_blue  {
	filter: invert(55%) sepia(63%) saturate(490%) hue-rotate(193deg) brightness(109%) contrast(102%);
}

.svg_white  {
	filter: invert(100%) sepia(100%) saturate(66%) hue-rotate(273deg) brightness(102%) contrast(102%);}

.svg_cerise {
 filter: invert(42%) sepia(50%) saturate(5986%) hue-rotate(291deg) brightness(90%) contrast(117%);
}

/* Slider */
ion-range::part(pin) {
	/* transform: translate3d(0px, -24px, 0px) scale(1); */
	margin-top: 18px;
}

ion-range {
	--pin-background: #000026;
	--pin-color: #fff;
	--knob-background: #E5E5E9;
	--bar-height: 10px;
	--knob-size: 28px;
	--bar-border-radius: 10px;
	--bar-background: #19193C;
}

ion-range.behavior_analysis_slider {
	--bar-background-active: linear-gradient(90deg, rgba(255, 0, 184, 1) 0%, rgba(64, 55, 161, 1) 100%);
}

ion-range.focus_setting_slider {
	--bar-background-active: linear-gradient(90deg, #ffccc8 0%, #BC80F0 100%);
}

ion-range.insight_slider {
	--bar-background-active: linear-gradient(90deg, #ffccc8 0%, #60b9a5 100%);
}

ion-range.energy_slider {
	--bar-background-active: linear-gradient(90deg, #ffccc8 0%, #5b6ef4 100%);
}

ion-range.motivation_slider {
	--bar-background-active: linear-gradient(90deg, #ffccc8 0%, #eaf47f 100%);
}

ion-range.leadership_slider {
	--bar-background-active: linear-gradient(90deg, #ffccc8 0%, #ff00b8 100%);
}

ion-toggle {
  --background: #fff;
  --background-checked: #fff;
  --handle-background: var(--blue);
  --handle-background-checked: var(--blue);
}

ion-modal {
	width: 100vw;
	height: 100vh;
}

.modal_animation {
    max-width: 540px;
    width: calc(100vh - 30%);
}

.expandable_left_content {
	margin-top: 24px;
}

/* breakpoints */
@media only screen and (max-width: 1200px) {
	.hide_medium {
		display: none;
	}
}

@media screen and (max-width: 1100px) {
	.bottom_margin_extra_large {
		margin-bottom: 90px;
	}
}

@media only screen and (max-width: 1000px) {
	.r-large-flex {
		display: block;
	}
	.col-1_2 {
		width: unset;
		display: block;
	}
	.col-1_3 {
		width: unset;
		display: block;
	}

	.page_section.half {
		width: unset;
		max-width: unset;
	}

	.page_section.third {
		width: unset;
		max-width: unset;
	}

	.page_section.quarter {
		width: unset;
		max-width: unset;
	}
	.min-w-360 {
		min-width: unset;
	}

	.r_left_margin_early_break {
		margin-left: 0;
	}

	.r_top_margin_early_break {
		margin-top: 20px;
	}

	.section_direction.early_break {
		flex-direction: column;
	}
	.chat_msg {
		max-width: 270px;
	}
}

@media only screen and (max-width: 768px) {
	.page_bar {
		margin: 5px 0;
	}

	.header_progress_container {
		left: 160px;
		padding: 0;
		width: calc(100vw - 240px);
	}

	.page_section.no_bg {
		background-color: unset;
		padding: 0px 15px 0px 15px;
	}	
	.half_page_section {
		width: 100%
	}
	.button_segment {
			width: 77px;
			height: 34px;
			font-size: 14px;

	}
	.button.mobile_size_btn {
		width: 100%;
	}

  .modal_animation {
      max-width: 540px;
      width: 100%;
  }

	.page_content {
		margin: 80px 0 0 0;
		padding-left: 15px;
		padding-right: 15px;
		width: 100vw;
	}

	.page_content.onboarding {
		margin: 88px 0 0 0;
	}

	.r_left_margin {
		margin-left: 0;
	}

	.r_top_margin {
		margin-top: 15px;
	}

	.btn_top_margin {
		margin-top: 20px;
	}

	.r-top-modal {
		padding-top: 175px;
	}

	.r-top-calendar {
		margin-top: 50px;
	}

	.r-bottom-modal {
		padding-bottom: 75px;
	}

	.r_right_margin {
		margin-right: 0px;
	}

	.bottom_margin {
		margin-bottom: 15px;
	}

	.top_margin {
		margin-top: 15px;
	}

	.mobile_mt-12 {
		margin-top: 3rem;
	}


	.double_top_margin {
		margin-top: 20px;
	}

	.right_margin {
		margin-right: 15px;
	}

	.left_margin {
		margin-left: 15px;
	}

	.page_section {
		padding: 15px;
	}

	.section_direction {
		flex-direction: column;
	}

	.section_direction.reverse {
		flex-direction: column-reverse;
	}
	.page_half_content {
		min-width: 49%;
		width: 100%;
	}

	.section_third {
		width: 100%;
	}

	.page_content.p-h-full {
		height: unset;
	}
	.header_navigation {
		padding: 16px 32px 16px 32px;
	}
	.modal_md {
		--height: 100vh;
		--width: 100vw;
	}

	.modal_r-full {
		--max-height: 100vh;
		--max-width: 100vw;
		--width: 100vw;
		--height: 100vh;
	}

	.onboarding_modal_content {
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-top: 145px;
	}
	.max_w_desktop {
		max-width: unset;
		width: unset;
	}
	.full_w_desktop {
		width: 100%;
	}

	.full_height {
		height: 100%;
	}

	.full_width {
		height: 100%;
	}

	.coach_picture {
		width: 100px;
		height: 100px;
	}
	.hide_large {
		display: block;
	}
	.hide_small {
		display: none;
	}
	.show_small {
		display: block;
	}
	.r-ml-4 {
		margin-left: unset;
	}
	.r-ml-8 {
		margin-left: unset;
	}
	.r-ml-12 {
		margin-left: unset;
	}
	.r-flex {
		display: block;
	}
	.r-half-col {
		width: unset;
	}

	.border_box {
		padding: 15px;
	}

	.border_box.no_padding {
		padding: 0 15px 15px 0;
	}

	.top_left_margin {
		padding: 15px 0 0 15px;
	}

	.r_top_margin_early_break {
		margin-top: 15px;
	}

	.extra_large_spacing {
		margin: 30px 0;
	}

	.extra_large_padding {
		padding: 30px;
	}
	.cal_no_x_padding {
		padding-left: 0!important;
		padding-right: 0!important;
	}
	.expandable_left_content {
		position: absolute;
		top: 0;
		left: -100vw;
		min-height: calc(100vh - 216px);
		margin-top: 0;
		width: calc(100vw - 48px);
		z-index: 1001;
		transition: left 0.5s;
	}
	.expandable_left_content.expanded {
		left: 0;
	}
	.expandable_toggle {
		position: absolute;
		left: calc(100vw - 32px);
		top: 20vh;
		width: 24px;
		height: 20vw;
		background-color: var(--blue_sky);
		border-radius: 0 4px 4px 0;
		display: flex;
		align-items: center;
		justify-content: space-around;
		z-index:1002;
	}
	.expandable_toggle.expanded {
		left: calc(100vw - 54px);
		border-radius: 4px 0 0 4px;
	}
}

hr.full-divider {
	width: 100%;
	border-top: 1px solid var(--neutral_600);
	margin-left: 0;
	margin-right: 0;
}

@media screen and (min-width: 769px) {
	.section_direction {
		flex-direction: row;
	}

	.section_third {
		width: 33%;
		max-width: 400px;
	}
}

@media screen and (min-width: 1200px) {
	.page_half_content.full_on_small {
		min-width: 49%;
	}
}
