.license_box {
	color: #fff;
	border-radius: 5px;
	padding: 40px;
	max-width: 495px;
  width: 100%;
	height: fit-content;
}

.license_bar {
	border: 1px solid #fff;
	background-color: #fff;
	margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
	.license_box {
		padding: 15px;
	}
}