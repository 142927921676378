/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	--ion-font-family: "Garnett", "sans-serif";
	/** primary **/
	--ion-color-primary: #d5f2a6;
	--ion-color-primary-rgb: 197, 242, 166;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9ae05c;
	--ion-color-primary-tint: #e5ffc6;
	/*--ion-color-primary: #3880ff;*/
	/*--ion-color-primary-rgb: 56, 128, 255;*/
	/*--ion-color-primary-contrast: #ffffff;*/
	/*--ion-color-primary-contrast-rgb: 255, 255, 255;*/
	/*--ion-color-primary-shade: #3171e0;*/
	/*--ion-color-primary-tint: #4c8dff;*/

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #000026;
	--ion-color-dark-rgb: 0, 0, 38;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** purple **/
	/*--ion-color-purple: #ca7cf8;*/
	/*--ion-color-purple-rgb: 202,124,248;*/
	/*--ion-color-purple-contrast: #000000;*/
	/*--ion-color-purple-contrast-rgb: 0,0,0;*/
	/*--ion-color-purple-shade: #b26dda;*/
	/*--ion-color-purple-tint: #cf89f9;*/
	--ion-color-purple: #bf77f6;
	--ion-color-purple-rgb: 191, 119, 246;
	--ion-color-purple-contrast: #000000;
	--ion-color-purple-contrast-rgb: 0, 0, 0;
	--ion-color-purple-shade: #a869d8;
	--ion-color-purple-tint: #c585f7;

	/** orange **/
	--ion-color-orange: #fcc762;
	--ion-color-orange-rgb: 252, 199, 98;
	--ion-color-orange-contrast: #000000;
	--ion-color-orange-contrast-rgb: 0, 0, 0;
	--ion-color-orange-shade: #deaf56;
	--ion-color-orange-tint: #fccd72;

	/** apricot **/
	--ion-color-apricot: #feccc8;
	--ion-color-apricot-rgb: 254, 204, 200;
	--ion-color-apricot-contrast: #000000;
	--ion-color-apricot-contrast-rgb: 0, 0, 0;
	--ion-color-apricot-shade: #e0b4b0;
	--ion-color-apricot-tint: #fed1ce;
}

.ion-color-purple {
	--ion-color-base: var(--ion-color-purple);
	--ion-color-base-rgb: var(--ion-color-purple-rgb);
	--ion-color-contrast: var(--ion-color-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-purple-shade);
	--ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-orange {
	--ion-color-base: var(--ion-color-orange);
	--ion-color-base-rgb: var(--ion-color-orange-rgb);
	--ion-color-contrast: var(--ion-color-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-orange-shade);
	--ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-apricot {
	--ion-color-base: var(--ion-color-apricot);
	--ion-color-base-rgb: var(--ion-color-apricot-rgb);
	--ion-color-contrast: var(--ion-color-apricot-contrast);
	--ion-color-contrast-rgb: var(--ion-color-apricot-contrast-rgb);
	--ion-color-shade: var(--ion-color-apricot-shade);
	--ion-color-tint: var(--ion-color-apricot-tint);
}
