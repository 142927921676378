.support_left_margin {
  margin-left: 41px;
}

.support_rotate_img {
	transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  .support_left_margin {
    margin-left: 36px;
  }
}