
.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.flex-1 {
	flex: 1 1 auto;
}

.flex-shrink {
	flex-shrink: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.flex-grow {
	flex-grow: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.items-center {
	align-items: center;
}

.align-center {
	align-self: center;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-between {
	justify-content: space-between;
}

/* The rest */

h1,
h2,
h3,
h4,
p {
	margin: unset;
}

.align-bottom {
	vertical-align: bottom;
}

.h-screen {
	height: 100vh;
}

.min-h-screen {
	min-height: 100vh;
}

.w-full {
	width: 100%;
}

.w-1 {
	width: 0.25rem;
}

.w-1\.5 {
	width: 0.375rem;
}

.w-2 {
	width: 0.5rem;
}

.w-2\.5 {
	width: 0.625rem;
}

.w-3 {
	width: 0.75rem;
}

.w-3\.5 {
	width: 0.875rem;
}

.w-4 {
	width: 1rem;
}

.w-5 {
	width: 1.25rem;
}

.w-6 {
	width: 1.5rem;
}

.w-7 {
	width: 1.75rem;
}

.w-8 {
	width: 2rem;
}

.w-9 {
	width: 2.25rem;
}

.w-10 {
	width: 2.5rem;
}

.w-11 {
	width: 2.75rem;
}

.w-12 {
	width: 3rem;
}

.w-14 {
	width: 3.5rem;
}

.w-16 {
	width: 4rem;
}

.w-20 {
	width: 5rem;
}

.w-24 {
	width: 6rem;
}

.w-28 {
	width: 7rem;
}

.w-32 {
	width: 8rem;
}

.w-36 {
	width: 9rem;
}

.w-40 {
	width: 10rem;
}

.w-44 {
	width: 11rem;
}

.w-48 {
	width: 12rem;
}

.w-52 {
	width: 13rem;
}

.w-56 {
	width: 14rem;
}

.w-60 {
	width: 15rem;
}

.w-64 {
	width: 16rem;
}

.w-72 {
	width: 18rem;
}

.w-80 {
	width: 20rem;
}

.w-96 {
	width: 24rem;
}

.w-1\/2 {
	width: 50%;
}

.h-full {
	height: 100%;
}

.h-1 {
	height: 0.25rem;
}

.h-1\.5 {
	height: 0.375rem;
}

.h-2 {
	height: 0.5rem;
}

.h-2\.5 {
	height: 0.625rem;
}

.h-3 {
	height: 0.75rem;
}

.h-3\.5 {
	height: 0.875rem;
}

.h-4 {
	height: 1rem;
}

.h-5 {
	height: 1.25rem;
}

.h-6 {
	height: 1.5rem;
}

.h-7 {
	height: 1.75rem;
}

.h-8 {
	height: 2rem;
}

.h-9 {
	height: 2.25rem;
}

.h-10 {
	height: 2.5rem;
}

.h-11 {
	height: 2.75rem;
}

.h-12 {
	height: 3rem;
}

.h-14 {
	height: 3.5rem;
}

.h-16 {
	height: 4rem;
}

.h-20 {
	height: 5rem;
}

.h-24 {
	height: 6rem;
}

.h-28 {
	height: 7rem;
}

.h-32 {
	height: 8rem;
}

.h-36 {
	height: 9rem;
}

.h-40 {
	height: 10rem;
}

.h-44 {
	height: 11rem;
}

.h-48 {
	height: 12rem;
}

.h-52 {
	height: 13rem;
}

.h-56 {
	height: 14rem;
}

.h-60 {
	height: 15rem;
}

.h-64 {
	height: 16rem;
}

.h-72 {
	height: 18rem;
}

.h-80 {
	height: 20rem;
}

.h-96 {
	height: 24rem;
}


.border {
	border: 1px solid #000;
}

.border_gray {
	border: 1px solid #dadada;
}

.border_white {
	border: 1px solid #fff;
}

.color_white {
	color: #fff;
}

.color_white_imp {
	color: #fff !important;
}

.color_black {
	color: #000;
}

.underline {
	text-decoration: underline;
}

.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.float_left {
	float: left;
}

.float_right {
	float: right;
}

.float_clear {
	clear: both;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.text_center {
	text-align: center;
}

.text_left {
	text-align: left;
}

.text_right {
	text-align: right;
}

.inline_block {
	display: inline-block;
}

.display_none {
	display: none;
}

.display_block {
	display: block;
}

.visibility_hidden {
	visibility: hidden;
}

.visibility_visible {
	visibility: visible;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.normal-case {
	text-transform: none;
}

.bold {
	font-weight: 600;
}

.whitespace-pre-line {
	white-space: pre-line;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.ml-auto {
	margin-left: auto;
}

.mt-auto {
	margin-top: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.myx-auto {
	margin: auto;
}

.ml-1 {
	margin-left: 0.25rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.ml-3 {
	margin-left: 0.75rem;
}

.mt-1 {
	margin-top: 0.25rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.mb-1 {
	margin-bottom: 0.25rem;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.mb-4 {
	margin-bottom: 1rem;
}

.mb-8 {
	margin-bottom: 2rem;
}

.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.m-4 {
	margin: 1rem;
}
.mt-4 {
	margin-top: 1rem;
}

.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.my-8 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.mt-6 {
	margin-top: 1.5rem;
}

.mt-8 {
	margin-top: 2rem;
}

.mt-10 {
	margin-top: 2.5rem;
}

.mt-12 {
	margin-top: 3rem;
}

.mt-16 {
	margin-top: 4rem;
}

.mt-24 {
	margin-top: 6rem;
}

.mt-32 {
	margin-top: 8rem;
}

.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mr-2 {
	margin-right: 0.5rem;
}

.mr-4 {
	margin-right: 1rem;
}

.mr-8 {
	margin-right: 2rem;
}

.mr-8 {
	margin-right: 2rem;
}

.mr-10 {
	margin-top: 2.5rem;
}

.mr-12 {
	margin-top: 3rem;
}

.mr-16 {
	margin-top: 4rem;
}

.mr-24 {
	margin-top: 6rem;
}

.mr-32 {
	margin-top: 8rem;
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mx-8 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.ml-4 {
	margin-left: 1rem;
}

.ml-8 {
	margin-left: 2rem;
}

.ml-10 {
	margin-top: 2.5rem;
}

.ml-12 {
	margin-top: 3rem;
}

.ml-16 {
	margin-top: 4rem;
}

.ml-24 {
	margin-top: 6rem;
}

.ml-32 {
	margin-top: 8rem;
}

.p-1 {
	padding: 0.25rem;
}

.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.p-2 {
	padding: 0.5rem;
}

.pt-2 {
	padding-top: 0.5rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.p-4 {
	padding: 1rem;
}

.pb-4 {
	padding-bottom: 1rem;
}

.pb-8 {
	padding-bottom: 2rem;
}

.pl-8 {
	padding-left: 2rem;
}

.pt-4 {
	padding-top: 1rem;
}

.pt-5 {
	padding-top: 1.25rem;
}

.pr-4 {
	padding-right: 1rem;
}

.pr-8 {
	padding-right: 2rem;
}

.pl-4 {
	padding-left: 1rem;
}

.pl-8 {
	padding-left: 2rem;
}

.py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.px-8 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.pt-8 {
	padding-top: 2rem;
}

.p-8 {
	padding: 2rem;
}

.space-y-1 > * + * {
	margin-top: 0.25rem;
}

.space-y-2 > * + * {
	margin-top: 0.5rem;
}

.space-y-3 > * + * {
	margin-top: 0.75rem;

}

.space-y-4 > * + * {
    margin-top: 1rem;
}

.space-y-5 > * + * {
	margin-top: 1.25rem;
}

.space-y-6 > * + * {
	margin-top: 1.5rem;
}

.space-y-7 > * + * {
	margin-top: 1.75rem;
}

.space-y-8 > * + * {
	margin-top: 2rem;
}


/* GRID */

.grid {
	display: grid;
}

.grid-cols-1 {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
	grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
	grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
	grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
	grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
	grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
	grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
	grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
	grid-template-columns: none;
}

/* GAP */ 
.gap-0 {
	gap: 0px;
}

.gap-x-0 {
	column-gap: 0px;
}

.gap-y-0 {
	row-gap: 0px;
}

.gap-px {
	gap: 1px;
}

.gap-x-px {
	column-gap: 1px;
}

.gap-y-px {
	row-gap: 1px;
}

.gap-0\.5 {
	gap: 0.125rem;
}

.gap-x-0\.5 {
	column-gap: 0.125rem;
}

.gap-y-0\.5 {
	row-gap: 0.125rem;
}

.gap-1 {
	gap: 0.25rem;
}

.gap-x-1 {
	column-gap: 0.25rem;
}

.gap-y-1 {
	row-gap: 0.25rem;
}

.gap-1\.5 {
	gap: 0.375rem;
}

.gap-x-1\.5 {
	column-gap: 0.375rem;
}

.gap-y-1\.5 {
	row-gap: 0.375rem;
}

.gap-2 {
	gap: 0.5rem;
}

.gap-x-2 {
	column-gap: 0.5rem;
}

.gap-y-2 {
	row-gap: 0.5rem;
}

.gap-2\.5 {
	gap: 0.625rem;
}

.gap-x-2\.5 {
	column-gap: 0.625rem;
}

.gap-y-2\.5 {
	row-gap: 0.625rem;
}

.gap-3 {
	gap: 0.75rem;
}

.gap-x-3 {
	column-gap: 0.75rem;
}

.gap-y-3 {
	row-gap: 0.75rem;
}

.gap-3\.5 {
	gap: 0.875rem;
}

.gap-x-3\.5 {
	column-gap: 0.875rem;
}

.gap-y-3\.5 {
	row-gap: 0.875rem;
}

.gap-4 {
	gap: 1rem;
}

.gap-x-4 {
	column-gap: 1rem;
}

.gap-y-4 {
	row-gap: 1rem;
}

.gap-5 {
	gap: 1.25rem;
}

.gap-x-5 {
	column-gap: 1.25rem;
}

.gap-y-5 {
	row-gap: 1.25rem;
}

.gap-6 {
	gap: 1.5rem;
}

.gap-x-6 {
	column-gap: 1.5rem;
}

.gap-y-6 {
	row-gap: 1.5rem;
}

.gap-7 {
	gap: 1.75rem;
}

.gap-x-7 {
	column-gap: 1.75rem;
}

.gap-y-7 {
	row-gap: 1.75rem;
}

.gap-8 {
	gap: 2rem;
}

.gap-x-8 {
	column-gap: 2rem;
}

.gap-y-8 {
	row-gap: 2rem;
}


/* GRID AUTO */

@media screen and (min-width: 640px) {
	.md\:grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media screen and (min-width: 768px) {
	.lg\:grid-cols-3 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media screen and (min-width: 1024px) {
	.xl\:grid-cols-3 {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

@media screen and (min-width: 1280px) {
	.\2xl\:grid-cols-3 {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* BORDE */


.border-left {
	border-left: 1px solid;
}

.border-neutral-600 {
    border-color: #4C4C67;
}

.rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}