/* Styles for screens less than 600px wide */
@media only screen and (max-width: 600px) {
    .flex.flex-col.items-center.justify-center.h-full.w-full {
      justify-content: space-between; /* Distribute space between */
    }
  
    /* Specifically target the button's flex container */
    #calendarEmptyNextBtn {
      margin-top: auto; /* Push to the bottom */
    }
  
    /* Add this to keep other items centered */
    #centeredItemsWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }