.calendar_container {
    height: 100%;
    width: 100%;
}

.calendar_header {
    display: flex;
    margin-left: 40px;
    justify-content: space-evenly;
    border: 1px solid #ccc;
    color: #fff;
}

.calendar_header_col {
    background-color: var(--medium_blue);
    color: #fff;
    text-align: center;
    border-left: 1px solid #ccc;
    padding: 6px 0;
}

.calendar_unbookable {
    color: #900;
}

.calendar_date_header {
    font-size: 20px;
}

.calendar_header_col.today {
    background-color: #fff;
    color: #000;
}

.calendar_date_container {
    height: 100%;
}
.calendar_content {
    height: 720px;
    border-right: 1px solid #ccc;
    color: #fff;
}

.calendar_content:after {
    content: '';
    position: absolute;
    bottom:0;
    left: 40px;
    width: calc(100% - 40px);
    height: 1px;
    border-bottom:1px solid #ccc;
}

.calendar_event {
    position: absolute;
    font-size: 14px;
    background-color: #237;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    border-radius: 6px;
}

.calendar_month_nr {
    position: absolute;
    font-size: 14px;
    padding: 0 6px;
    border-radius: 7px;
}

.calendar_grid {
    width: calc(100% - 40px);
    /*width: 100%;*/
    height: 100%;
    position: relative;
    background-image:
            repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
            repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
}

.calendar_dot {
    background-color: #efeef1;
    opacity: 0.5;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    align-self: center;
}

.calendar_top_banner {
    flex-wrap: wrap;
}

.cal_date_header {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .calendar_event {
        font-size: 12px;
    }

    .calendar_date_banner {
        width: calc(100vw - 30px);
        margin-bottom: 20px;
    }

    ion-segment-button.ios.cal_segment_button::part(native) {
        --padding-start: 15px;
        --padding-end: 15px;
}
}


@media screen and (min-width: 900px) {
    .cal_min_width{
        min-width: 200px;
    }

    .calendar_top_banner {
        justify-content: space-between;
    }

    .cal_date_header {
        width: 62%;
    }

}