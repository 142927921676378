.client_purchase_bg {
  background-image: url('/assets/images//blue_stripey_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.client_purchase_upgrade {
  background-color: #20203D;
  width: 369px;
  max-width: 100%;
  margin: 0 auto;
  padding: 39px 23px 59px 24px;
  border-radius: 14px;
}

.client_purchase_bar {
  background-color: #878798;
  height: 1px;
  width: 322px;
  margin: 0 auto;
  max-width: 100%;
}

.client_purchase_dot {
  background-color: #fff;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
}

.client_purchase_checkout {
  background-color: #20203D;
  padding: 12px;
  max-width: 799px;
  margin: 0 auto;
  border-radius: 14px;
}