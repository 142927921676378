/* Focus area card */
.focus_area_card {
    width: 392px;
    height: 523px;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
}

.mt-10 {
    margin-top: '10px'
}

.focus_area_card.future {
    background-image: url('/assets/focusArea/future_card.svg');
}

.focus_area_card.self_insight {
    background-image: url('/assets/focusArea/self_insight_card.svg');
}

.focus_area_card.relation {
    background-image: url('/assets/focusArea/relation_card.svg');
}

.focus_area_card.motivation {
    background-image: url('/assets/focusArea/motivation_card.svg');
}

.focus_area_card.prestation {
    background-image: url('/assets/focusArea/prestation_card.svg');
}

.focus_area_card.stress {
    background-image: url('/assets/focusArea/stress_card.svg');
}

.focus_area_lock {
    width: 50px;
    padding: 10px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF405E;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.focus_area_card_text {
    position: absolute;
    bottom: 40px;
    left: 40px;
    padding-right: 40px;
}

.question_done_checkmark {
    top: -25px;
    right: -25px;
}

/* Card help modal */
.help_modal {
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #000026;
    overflow-y: scroll;
}

.modal_close {
    right: 40px;
    top: 40px;
    cursor: pointer;
}

/* Colors */
.task_color_0 {
    color: var(--area1);
}

.task_color_1 {
    color: var(--area2);
}

.task_color_2 {
    color: var(--area3);
}

.task_color_3 {
    color: var(--area4);
}

.task_bgcolor_0 {
    background-color: var(--area1);
}

.task_bgcolor_1 {
    background-color: var(--area2);
}

.task_bgcolor_2 {
    background-color: var(--area3);
}

.task_bgcolor_3 {
    background-color: var(--area4);
}

.task_circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.task_gradient_0 {
    background: linear-gradient(to bottom, var(--area1), #ffccc8);
}

.task_gradient_1 {
    background: linear-gradient(to bottom, var(--area2), #ffccc8);
}

.task_gradient_2 {
    background: linear-gradient(to bottom, var(--area3), #ffccc8);
}

.task_gradient_3 {
    background: linear-gradient(to bottom, var(--area4), #ffccc8);
}

/* Question card */

.task_number {
    font-size: 40px;
    font-weight: 600;
}

.task_progress_direction {
    flex-grow: 1;
    flex-basis: 0;
}

.task_progress {
    width: 80px;
    height: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--medium_blue)
}

.task_progress.active {
    border: 2px solid #fff;
}

.task_question {
    background-color: #ffccc8;
    padding: 20px;
    color: var(--dark_blue);
    width: 558px;
    border-radius: 5px 5px 5px 0;
}

.task_question_arrow {
    width: 15px;
    height: 8px;
    display: block;
    background-image: url('data:image/svg+xml;utf8,<svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0C8 0 4.76837e-06 4.79227 4.76837e-06 7.98711V0H15Z" fill="%23FFCCC8"/></svg>')
}

.task_answer {
    background-color: #EFEFEF;
    padding: 20px;
    color: #000;
    width: 558px;
    border-radius: 5px 5px 0 5px;
}

.task_answer_arrow {
    position: absolute;
    right: 0;
    bottom: -9px;
    width: 15px;
    height: 10px;
    background-image: url('data:image/svg+xml;utf8,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.4375C7 0.4375 15 9.175 15 15V0.4375H0Z" fill="%000026"/></svg>')
}

.task_divider {
    height: 1px;
    background-color: #60608B;
    align-self: center;
    margin: 10px 0;
}

.task_question_divider {
    width: calc(100% - 80px);
    top: 121px;
}

.task_plan_divider {
    width: calc(100% - 80px);
    left: 40px;
    margin-top: 40px;
}

.task_summary_divider {
    position: absolute;
    left: 40px;
    width: calc(100% - 80px);
}

.task_text_area {
    background-size: cover;
    padding: 20px 20px 0 20px;
    color: #fff;
    border-radius: 5px 5px 0 0;
    border-right: #60608B 1px solid;
    border-left: #60608B 1px solid;
    border-top: #60608B 1px solid;
    border-bottom: none;
    width: 558px;
    min-height: 86px;
    height: 100%;
}

.task_text_area:focus {
    color: #fff;
    /*height: 218px;*/
}

.task_text_area::placeholder {
    color: #60608B;
}

.task_filter_option:hover {
    color: #60608B;
}

.task_done_checkbox {
    background-image: url('/assets/focusArea/answer_box.svg');
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    background-color: #000026;
    padding: 20px 20px 25px 20px;
    border-radius: 0 0 0 5px;
    color: #fff;
}

.task_radio_input {
	display: inline-block;
	width: 20px;
	height: 20px;
    background-color: #000026;
	border: 1px solid #fff;
    color: var(--dark_blue);
	border-radius: 5px;
}

.task_radio_input.task_plan {
    background-color: #FFCCC8;
	border: 1px solid #000026;
    color: var(--dark_blue);
}

.task_done_checkbox.disabled {
    color: #60608B;
    pointer-events: none;
}

.task_radio_input.disabled {
    border: #60608B 1px solid;
    cursor: default;
}

.followup_modal {
    height: 100%;
    background-color: #94ABFF;
    align-items: center;
}

.task_plan_instruction:hover {
    color: #60608B;
}

.followup_radio_btn {
	height: 24px;
	min-width: 24px;
	border: 2px solid #fff;
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
	box-sizing: border-box;
}

.followup_radio_btn:hover {
	border: 2px solid #D1F19E;
}

.followup_radio_btn.selected {
	border: 2px solid #000026;
	background-color: #D1F19E;
	box-shadow:
    0 0 0 1px #D1F19E;
}

.task_header {
    position: fixed;
    width: calc(100vw - 170px);
    max-width: 1296px;
    top: 80px;
    border-radius: 12px;
    padding: 40px 40px 33px 40px;
    background-color: var(--dark_blue);
    z-index: 2001;
   box-shadow: 0px 4px 12px #20203d;
}


.page_section.task_section {
    position: relative;
    top: 143px;
    padding: 40px;
    /*margin-bottom: 210px;*/
}

.task_bar {
    height: 1px;
    background-color: #60608B;
    width: 93%;
    margin: 0 auto;
    margin-top: 20px;
}

.question_card_done_marker {
    position: absolute;
    z-index: 10;
    right: -10px;
    top: -10px;
}

.task_done_banner {
    justify-content: end;
}

.focus_area_setting_page {
    padding: 50px 0 182px 0;
}

.tasks_rec_banner {
    position: absolute;
    right: -33px;
    top: -32px;
}


.task_area_intro {
    overflow: scroll;
}

/* Media queries */
@media screen and (max-width: 474px){
    .focus_icon {
        display: none;
    }

    .focus_help_banner {
        position: absolute;
        left: 15px;
        bottom: 20px;
    }

    .focus_stats_banner {
        position: absolute;
        left: 15px;
        top: 20px;
    }

    .carousel_top_banner {
        min-height: 140px;
    }

    .focus_area_setting_page {
        padding: 30px 0;
    }
}

@media screen and (min-width: 475px) {
    .focus_help_banner {
        position: absolute;
        left: 120px;
        bottom: 20px;
    }

    .focus_stats_banner {
        position: absolute;
        left: 120px;
        top: 20px;
    }

    .carousel_top_banner {
        min-height: 140px;
    }
}

@media screen and (max-width: 768px) {
    .task_header {
        width: calc(100vw - 30px);
        padding: 15px;
        background-color: var(--dark_blue);
        z-index: 2001;
    }

    #summarySegmentBtn {
        --padding-start: 12px;
        --padding-end: 12px;
    }

    
    .task_question_divider {
        width: calc(100% - 30px);
    }
        
    .task_plan_divider {
        width: calc(100% - 30px);
        left: 15px;
        margin-top: 40px;
    }

    .focus_area_card_section {
        flex-direction: column;
        justify-content: center;
    }

    .focus_area_card{
        margin: 0 auto;
        width: 340px;
        margin-bottom: 20px;
    }

    .focus_area_lock {
        right: 15px;
    }

    .task_progress_direction {
        padding-bottom: 8px;
        flex-basis: auto;
    }

    .question_done_checkmark {
        top: -35px;
        right: -35px;
    }

    .help_modal {
        height: 100vh;
        padding: 45px 30px;
    }

    .modal_close {
        right: 10px;
        top: 75px;
    }

    .task_question, .task_text_area {
        max-width: calc(100vw - 100px);
    }

    .focus_area_card_text {
        left: 20px;
        bottom: 20px;
        padding-right: 20px;
    }

    .focus_area_checkmark {
        top: -10px;
        right: -5px;
    }

    .page_section.task_section {
        top: 89px;
        padding: 15px 15px 15px 15px;
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 769px) {
    .focus_area_checkmark {
        top: -15px;
        right: -5px;
    }

    .focus_area_card_section {
        flex-wrap: wrap;
        justify-content: center;
    }    

    .focus_area_card {
        margin-bottom: 20px;
        margin-right: 20px;
    }    
}




@media screen and (max-width: 868px) {
    .profile_image_hide_small {
        display: none;
    }

    .task_question, .task_text_area {
        width: 458px;
    }

    .focus_area_results {
        flex-wrap: wrap;
    }

        .task_arrow_container {
        height: 150px;
    }

    .tasks2_arrow {
        transform: rotate(90deg);
        height: 25px;
    }


    .task_intro {
        flex-direction: column;
    }
}

@media screen and (min-width: 900px) {
    .focus_help_banner {
        position: initial;
    }

    .focus_stats_banner {
        position: initial;
    }
}

@media screen and (max-width: 1345px) {
    .focus_area_intro {
        flex-direction: column;
    }

    .focus_area_intro div {
        min-width: fit-content;
    }

    .task_done_banner {
        justify-content: start;
    }

}


@media screen and (min-width: 1345px) {
    .focus_area_card {
        margin-right: 0px;
    }    

    .focus_area_card_section {
        justify-content: space-between;
    }    
}