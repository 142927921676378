.vb_top_col {
	background-color: #fff;
	color: #333;
	border-radius: 12px;
	padding: 16px;
	flex: 1 1 auto;
	flex-direction: column;
	min-width: calc(33% - 8px);
}

.vb_top_col.vb_second {
	margin: 0 0 0 8px;
}

.vb_textarea {
	/*height: 31vh;*/
	height: 250px;
}

.vb_media {
	background-color: #fff;
	border-radius: 12px;
	padding: 16px;
	display: flex;
	position: relative;
	height: calc(100vh - 175px - 250px);
}

.vb_media.expand {
	height: calc(100vh - 175px);
}

.vb_add_media {
	display: flex;
	position: absolute;
	right: 12px;
	bottom: 12px;
}

.vb_input_text {
	font-size: 12px;
	background-color: #fff;
	color: #000 !important;
	border: unset;
	width: 100%;
	height: calc(100% - 70px);
}

.vb_sound {
	position: relative;
	border: 1px solid var(--blue);
	border-radius: 1vw;
	padding: 16px 8px;
	background-color: var(--lighter_blue);
	display: inline-block;
	margin: 16px;
	vertical-align: top;
}

.vb_play {
	margin-top: 8px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.vb_flex {
	display: flex;
}

.vb_clip {
	width: calc(20vw + 2px);
	position: relative;
	height: 20vw;
	border: 1px solid var(--blue);
	border-radius: 1vw;
	padding-top: 16px;
	background-color: var(--lighter_blue);
	display: inline-block;
	margin: 16px;
	vertical-align: top;
}

.vb_clip_video {
	width: 20vw;
	margin-top: 12px;
}

.vb_border_radius {
	border-radius: 1vw;
}

.vb_picture {
	width: 20vw;
	height: 20vw;
	display: inline-block;
	position: relative;
	margin: 16px;
}

.vb_delete {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}

.vb_picture_small {
	width: 150px;
	height: 150px;
	border-radius: 4px;
	display: inline-block;
	margin: 16px;
}

.vb_picture_small.chosen {
	opacity: 0.5;
}

@media only screen and (max-width: 1100px) {
	.vb_top_col {
		padding: 16px;
		flex: 1 1 auto;
	}
	.vb_top_col.vb_second {
		margin: 15px 0 0 0;
	}
	.vb_media {
		display: block;
		height: unset;
		min-height: 100vw;
	}
	.vb_media.expand {
		height: unset;
		min-height: 100vw;
	}
	.vb_flex {
		display: block;
	}
	.vb_picture {
		width: 300px;
		height: 300px;
		max-width: calc(100vw - 96px);
		margin: 8px 12px 0 0;
	}
	.vb_clip_video {
		width: 298px;
	}
	.vb_clip {
		width: 300px;
		height: 300px;
	}
}
