/* CSS Styles */
.flex {
    display: flex;
    flex-wrap: wrap;
  }
  
  .text-sm {
    font-size: 12px;
  }
  
  .font-medium {
    font-weight: 500;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-gray-500 {
    color: #6B7280;
  }
  
  .mr-2 {
    margin-right: 8px;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .rounded-lg {
    border-radius: 8px;
  }
  
  /* Dark mode */
  .dark .text-gray-400 {
    color: #9CA3AF;
  }
  
  /* Active tab */
  .active {
    background-color: #3B82F6;
    color: white;
  }
  
  /* Hover styles */
  .hover-text-gray-900:hover {
    color: #111827;
  }
  
  .hover-bg-gray-100:hover {
    background-color: #F3F4F6;
  }
  
  .dark .dark-hover-bg-gray-800:hover {
    background-color: #1F2937;
  }
  
  .dark .dark-hover-text-white:hover {
    color: #FFFFFF;
  }
  
  /* Disabled tab */
  .text-gray-400 {
    color: #9CA3AF;
  }
  
  .cursor-not-allowed {
    cursor: not-allowed;
  }
