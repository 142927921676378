.measure_progress_bar_divider {
  background-color: #fff;
  height: 2px;
  width: 58px;
}

.measure_progress {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.measure_progress.insight {
  background: #60b9a5;
}

.measure_progress.energy {
  background: #5b6ef4;
}

.measure_progress.motivation {
  background: #eaf47f;
}

.measure_progress.leadership {
  background: #ea74ef;
}

.measure_progress_bar {
  max-width: 375px;
  margin: 0 auto;
}

@media screen and (min-width: 900px) {
  .measure_progress_bar_divider {
    width: 93px;
  }

  .measure_progress_text {
    margin-left: -20px;
    min-width: 435px;
  }
}