.signout_section {
	background-color: var(--dark_blue);
	min-height: 100vh;
	color: #fff;
	padding: 50px 79px;
}

.signout_text h1 {
	padding-bottom: 30px;
}

.signout_button {
	padding-top: 30px;
}

@media screen and (max-width: 799px) {
	.signout_text {
		padding: 120px 0 30px 20px;
	}

	.signout_section {
		padding: 30px;
	}
}

@media screen and (min-width: 800px) {
	.signout_text {
		padding: 188px 45px 188px 188px;
	}

	.signout_section {
		padding: 30px 79px;
	}
}
