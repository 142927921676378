.license_package_section {
	background-color: var(--blue);
	color: #fff;
	border-radius: 5px;
	padding: 40px;
	max-width: 850px;
}

.license_package_list {
	line-height: 25px;
}

.license_section {
	border: 1px solid #fff;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.license_full_height {
	height: 100%;
	min-height: 450px;
}

.klarna_license {
	background-color: #94ABFF;
	padding: 46px 40px;
}

@media screen and (max-width: 768px) {
	.license_package_section {
		padding: 15px;
	}
}
