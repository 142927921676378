.client_dashboard_second_box {
	margin-left: 20px;
	margin-top: 0;
}

.client_dashboard_direction {
	flex-direction: row;
}

.client_dashboard_speedometer {
	width: 180px;
}

@media screen and (min-width: 1001px) {
	.client_dashboard_summary {
		flex-direction: column;
		min-height: 300px;
	}
}

@media screen and (min-width: 1220px) {
	.client_dashboard_summary {
		flex-direction: row;
	}
}

@media screen and (min-width: 1320px) {
	.client_dashboard_speedometer {
		width: 220px;
	}
}

@media screen and (max-width: 1000px) {
	.client_dashboard_second_box {
		margin-left: 0;
		margin-top: 20px;
	}

	.client_dashboard_direction {
		flex-direction: column;
	}

	.client_dashboard_speedometer {
		width: 220px;
}
}

@media screen and (max-width: 768px) {
	.client_dashboard_second_box {
		margin-top: 15px;
	}
}
