.show_modal {
  --width: 390px;
  --position: relative;
  color: #fff;
  --border-radius: 5px;
}

.show_modal_content {
  background-color: #19193C;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding:20px 15px;
}

.show_modal.small {
  --height: 30vh;
}

.show_modal.medium {
  --height: 50vh;
}

.show_modal_title {
  padding: 15px;
}

.show_modal_button {
  padding: 15px;
}

.show_modal_close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 989738290000;
  cursor: pointer;
}