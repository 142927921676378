.support_sub_title {
	padding: 20px 0 12px 0;
}

.support_rotate_img {
	transform: rotate(45deg);
}

.support_divider {
	margin-top: 16px;
}

.support_question {
	padding-top: 8px;
}

.support_button_div {
	margin-top: 20px;
	max-width: 496px;
	width: 100%;
}

.support_question_section {
	max-width: 500px;
	width: 100%;
}

.support_black_link {
	color: #fff;
}

.support_black_link:hover {
	color: var(--blue);
}

.support_plus {
	margin-left: 20px;
}

@media screen and (max-width: 768px) {
	.support_sub_title {
		padding: 15px 0;
	}

	.support_button_div {
		margin-top: 15px;
	}
}
