.top_menu {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	top: calc(var(--ion-safe-area-top, 0) + 21px);
	right: 58px;
	z-index: 2002;
	cursor: pointer;
}

.dropdown_menu {
	display: none;
}

.dropdown_menu.show {
	position: fixed;
	display: flex;
	top: calc(var(--ion-safe-area-top, 0) + 12px);
	right: 49px;
	background-color: #000026;
	color: #fff;
	z-index: 2005;
	border-radius: 0 26px 0 8px;
	padding: 9px;
	line-height: 28px;
}

.dropdown_link {
	color: #fff;
	margin-top: 9px;
}

.dropdown_image {
	margin-left: 20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.menu_hamburger_link {
	padding-left: 26px;
}

/* Mark unread support and chat messages */
.badge_marker {
	background-color: #ff0000;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	position: absolute;
	right: 1px;
	top: 20px;
}

.expanded_badge_marker {
	position: absolute;
	right: 10px;
	top: 16px;
	color: #fff;
	background-color: #FF405E;
	width: 22px;
	height: 22px;
	text-align: center;
	border-radius: 5px;
}

.expanded_badge_marker.selected {
	color: #000;
	background-color: #fff;
}

.expanded_new_marker {
	position: absolute;
	right: 10px;
	top: 16px;
	color: #fff;
	background-color: #FF405E;
	font-size: 9px;
	padding: 5px;
	text-align: center;
	border-radius: 50%;
}

.expanded_new_marker.eng {
	font-size: 8px;
	padding: 8px 5px;
}

.expanded_new_marker.closed {
	right: 3px;
	top: 24px;
	padding: 2.5px;
}

.zebrain {
	padding-top: 17px;
}

.menu_logo {
	margin: 27px 0 20px 12px;
	height: 27px;
	width: 27px;
	background-image: url(/assets/icon/green/logo_text.svg);
	background-repeat: no-repeat;
	background-size: cover;
}

.menu_logo.wide {
	width: 156px;
}

.menu_text {
	margin-left: 0;
	width: 203px;
	visibility: hidden;
	transition: visibility 0s;
}

.menu_text.wide {
	visibility: visible;
	transition: visibility 0s 0.1s;
	margin-left: 12px;
}

.w25vw {
	width: 25vw;
}

.hamburger_menu {
	background-color: var(--dark_blue);
	position: fixed;
	top: var(--ion-safe-area-top, 0);
	left: 100vw;
	height: calc(100vh - var(--ion-safe-area-top, 0));
	width: 100vw;
	z-index: 20000;
	transition: left 0.25s;
	color: #fff;
	overflow-y: scroll;
	padding-bottom: 120px;
}

.hamburger_menu.open {
	left: 0;
	transition: left 0.25s;
}

.menu_bar1,
.menu_bar2,
.menu_bar3 {
	width: 24px;
	height: 2px;
	background-color: #fff;
	margin: 6px 0;
	transition: 0.4s;
}

.menu_bar {
	border: 1px solid #fff;
	margin-top: 20px;
	margin-bottom: 20px;
}

.menu_bar.wide {
	width: 230px;
	transition: width 0.25s;
}

/* Rotate first bar */
.change_bars .menu_bar1 {
	-webkit-transform: rotate(-45deg) translate(-6px, 5px);
	transform: rotate(-45deg) translate(-6px, 5px);
}

/* Fade out the second bar */
.change_bars .menu_bar2 {
	opacity: 0;
}

/* Rotate last bar */
.change_bars .menu_bar3 {
	-webkit-transform: rotate(45deg) translate(-6px, -6px);
	transform: rotate(45deg) translate(-6px, -6px);
}

/* Mark selected category */
.selected_link {
	background-color: #fff;
	border-radius: 0 8px;
	height: 52px;
	min-width: 52px;
	color: #000;
}

.unselected_link {
	background-color: var(--dark_blue);
	height: 52px;
	border-radius: 0 8px;
	color: #fff;
}

.menu_link:hover {
	background-color: var(--blue);
}

.menu_footer_link {
	background-color: var(--dark_blue);
	height: 81px;
}

.menu_footer_link.selected {
	background-color: #fff;
}

@media screen and (max-width: 768px) {
	.client_name,
	.top_menu {
		display: none;
	}

	.hamburger_top_menu {
		display: flex;
		position: fixed;
		top: 23.5px;
		right: 20px;
		z-index: 2003;
	}

	.left_menu {
		display: none;
	}

	.menu_icon {
		padding: 34px 0 36px 25px;
	}

	.menu_bar {
		width: 90vw;
		margin: 20px auto;
	}

	.expanded_new_marker {
		right: 34px;
	}

	.logo_hamburger {
		margin: 27px 0 27px 24px;
		height: 27px;
		width: 160px;
		background-color: var(--dark_blue);
	}

	/* Selected tab in mobile view */
	.selected_link {
		height: 60px;
		color: #000;
		border-radius: 0;
	}

	.selected_link.footer {
		height: 81px;
	}

	.menu_link:hover, .menu_link:active {
		background-color: var(--dark_blue);
		color: #fff;
	}
	
	.unselected_link {
		height: 60px;
		border-radius: 0;
	}

	.unselected_link.footer {
		height: 81px;
	}
}

@media screen and (min-width: 768px) {
	.hamburger_top_menu {
		display: none;
	}

	/* Hide hamburger menu in desktop view */
	.hamburger_menu {
		left: 100vw;
	}

	.hamburgerMenu.open {
		left: 100vw;
	}

	/* General styling of the menus */
	.client_name {
		padding-right: 15px;
	}

	.left_menu {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 2002;
		background-color: var(--dark_blue);
		height: 100vh;
		width: 102px;
		padding-left: 25px;
		padding-right: 25px;
		transition: width 0.25s;
	}
	.left_menu.wide {
		width: 280px;
	}

	.menu_icon {
		padding-top: 15px;
		margin-left: 15px;
		margin-bottom: 15px;
	}

	.menu_bar {
		width: 57px;
	}
}
