
.md-newline {
    display: block;
    height: 0px;
}

.md-container p > br {
    display: block;
    height: 0px;
    display: none;
}

/* .md-container p > span {
    display: block;
    height: 6px;
} */

/* .md-container > ul, ol {
    padding-bottom: 12px;
} */

.md-container > ul > li {
    padding-bottom: 6px;
}

.md-container > ol > li {
    padding-bottom: 6px;
}

.image_modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.close_button_container {
    display: flex;
    justify-content: flex-end;
}
