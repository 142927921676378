.notfound_client {
	margin: auto auto;
	text-align: center;
}

.notfound_logo {
	height: 40px;
}

.notfound_container {
	height: 100%;
	display: block;
	text-align: center;
	color: #000026;
	background-color: #e8efff;
}

.notfound_header {
	background-color: #000026;
	height: max-content;
	color: #e8efff;
	padding: 2rem;
	text-align: left;
}

.notfound_h1 {
	font-size: 10rem;
	padding: 10rem 8rem 4rem 8rem;
	color: #ff405e;
	font-weight: 900;
}

.notfound_goto {
	font-size: 2rem;
	padding: 2rem;
	margin: 0 auto;
}

.notfound_goto_link {
	color: #bc80f0;
	text-decoration: underline;
	font-size: 2rem;
	text-align: center;
}

@media only screen and (max-width: 585px) {
	.notfound_h1 {
		font-size: 3rem;
	}
	.notfound_h2 {
		font-size: 1.6rem;
		padding-top: 1rem;
	}
	.notfound_goto {
		font-size: 1.4rem;
		padding: 0;
	}
	.notfound_goto_link {
		color: #bc80f0;
		text-decoration: underline;
		font-size: 1.4rem;
		text-align: center;
	}
}
