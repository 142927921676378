.pa_circle_box {
	position: relative;
	height: 360px;
}

.pa_top {
	position: absolute;
	top: 0;
	left: calc(50% - 86px);
}

.pa_left {
	position: absolute;
	top: 100px;
	left: 0;
}

.pa_right {
	position: absolute;
	top: 100px;
	right: 0;
}

.pa_bottom {
	position: absolute;
	top: 200px;
	left: calc(50% - 86px);
}

.pa_background {
  background: linear-gradient(#000026, #000026) padding-box,
              linear-gradient(to bottom, #4037A1, 75%, #FF00B8) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
}

.pa_gradient_cerise {
	background-image: linear-gradient(to bottom, #4037A1, 75%, #FF00B8);
}

.pa_gradient_cerise_45 {
	background-image: linear-gradient(-45deg, #FF00B8, #4037A1);
}

.pa_oval_button {
	border-radius: 20px;
	padding: 6px 20px;
	font-size: 16px;
}

.pa_outer_circle {
	width: 172px;
	height: 172px;
	padding: 6px;
	border-radius: 50%;
}

.pa_inner_circle {
	width: 160px;
	height: 160px;
	padding: 24px 14px;
	border-radius: 50%;
}

.behavior_modal {
	padding: 40px;
	color: #fff;
	text-align: center;
}

@media screen and (max-width: 768px) {
	.behavior_modal {
		padding: 15px;
	}
}

@media only screen and (max-width: 1200px) {
	.pa_circle_box {
		height: 652px;
	}

	.pa_left {
		top: 160px;
		left: 0;
	}

	.pa_right {
		top: 320px;
		right: 0;
	}

	.pa_bottom {
		top: 480px;
		left: calc(50% - 86px);
	}
}
