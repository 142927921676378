@font-face {
    font-family: 'Garnett';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Garnett-Regular.ttf');
}

@font-face {
    font-family: 'Garnett';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Garnett-Medium.ttf');
}

@font-face {
    font-family: 'Garnett';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Garnett-Semibold.ttf');
}
