.recommendations_container {
  max-width: 1296px;
  width: 100%;
  border-radius: 5px;
  padding: 16px 16px 40px 40px;
}

.recommendations_container.no_height {
  height: unset;
  max-height: unset;
}

.recommendations_container.red {
  background: linear-gradient(0deg, rgba(255,204,200,1) 0%, rgba(255,64,94,1) 100%);
}

.recommendations_container.pink_purple {
  background: radial-gradient(102.47% 117060.67% at 0% 100%, #FFCCC8 0%, #BC80F0 100%);
}

.recommendations_container.pink_green {
  background: radial-gradient(102.47% 117060.67% at 0% 100%, #FFCCC8 0%, #60B9A5 100%);
}

.recommendations_container.blue_pink_cross {
  background: linear-gradient(45deg, #94ABFF 0%, #FF9B8A 100%);
}

.recommendations_container.purple_yellow {
  background: linear-gradient(0deg, #DDF19E 0%, #BC80F0 100%);
}

.recommendations_container.purple_blue {
  background: linear-gradient(0deg, #94ABFF 0%, #BC80F0 100%);
}

.recommendations_container.purple_green {
  background: linear-gradient(90deg, #60B9A5 0%, #BC80F0 100%);
}

.recommendations_container.blue_pink {
  background: linear-gradient(45deg, #94ABFF 0%, #FF2DD1 100%);
}

.recommendation_circle {
  border: 1px solid #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.recommendations_text {
  width: 100%;
  max-width: 800px;
}

.recommendations_nps_circle {
  font-size: 14px;
  border: 1px solid #000026;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  padding-top: 9px;
  margin-top: 10px;
  color: #000026;
}

.recommendations_nps_circle:hover {
  border: 2px solid #000026;
  padding-top: 8px;
}

.recommendations_nps_circle.chosen {
  color: #fff;
  background-color: #000026;
}

.recommendations_nps_error {
  font-size: 14px;
  color: var(--error_red);
}

@media screen and (max-width: 768px) {
  .recommendations_nps_circle {
    width: 20px;
    height: 20px;
    padding-top: 0;
    font-size: 9px;
    padding-top: 2px;
    margin-top: 15px;
  }

  .recommendations_nps_circle.chosen {
    border: 2px solid #fff;
    color: #fff;
    padding-top: 1px;
  }

  .recommendations_container {
    padding: 15px;
  }
}