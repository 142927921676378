.edit_img {
    max-width: 100vw;
    max-height: 50vh;
}

.show_edit {
    visibility: visible;
}

.range_container {
    display: flex;
}

.hide_edit {
    visibility: hidden;
}

.result_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
