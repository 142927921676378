.chat_who {
    color: #ccc;
    font-size: 12px;
}

.chat_container {
    width: 100%;
    max-width: 732px;
    height: calc(100vh - 218px + 20px);
    background-color: var(--dark_blue);
    padding: 20px;
    border: 1px solid var(--border_blue);
    border-radius: 5px;
}

.bg_chat_container {
    background-color: #efefef;
}

.chat_container.video_chat {
    position: absolute;
    z-index: 5000;
    width: 395px;
    bottom: 90px;
}

.chat_msg_display {
    padding-bottom: 10px;
    min-height: 20px;
}

.chat_window {
    position: fixed;
    z-index: 1020;
    bottom: 90px;
    width: 370px;
    left: 8px;
}

.chat_with_title {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    padding-left: 25px;
}

.chat_send_btn {
    background-color: #fff;
    border-radius: 0 5px;
    padding: 10px;
}

.chat_light_mode {
    background-color: #efefef;
    padding: 20px;
    border: 1px solid #efefef;
    border-radius: 5px;
    max-height: 614px;
}

.chat_window.standalone {
    width: 100%;
    max-width: 732px;
    position: relative;
    bottom: 0;
    height: 100%;
    left: 0;
}

.chat_scroll {
    width: 100%;
    max-height: 514px;
    color: var(--dark_blue);
}

.chat_scroll.standalone {
    max-height: 484px;
}

.chat_area {
    width: 100%;
    max-width: 495px;
    height: 35px;
    margin: 0 10px;
    padding: 5px;
}

.chat_text {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 6px 12px 4px 12px;
}

.chat_text:focus {
    color: #fff;
    /*height: 80px;*/
}

.chat_text::placeholder {
    color: var(--neutral_400)
}

.chat_textbox_border{
    width: 100%;
    height: 100%;
    border: 1px solid var(--border_blue);
    border-radius: 5px;
}

@media only screen and (max-width: 1000px) {
    .chat_window {
        width: 100vw;
        height: calc(60vh - 90px);
    }
    .chat_window.standalone {
        left: 0;
        height: unset;
    }
    .chat_scroll {
        max-height: 320px;
    }
    .chat_area {
        width: calc(100% - 50px)
    }

    .chat_container {
        border: unset;
        padding: 20px 0;
    }

    .chat_container.video_chat {
        max-height: 350px;
    }
}
