.breadcrumbsMain {
	position: fixed;
	display: flex;
	align-items: center;
	left: 102px;
	top: 0;
	height: 80px;
	width: 100vw;
	background-color: var(--medium_blue);
	z-index: 2001;
}

.breadcrumbsName {
	padding-left: 20px;
}

.breadcrumbsBack {
	cursor: pointer;
	width: 32px;
}

.breadcrumbsLink {
	cursor: pointer;
	padding-left: 20px;
}

.breadcrumbsLarge {
	display: flex;
	align-items: center;
}

.breadcrumbsSmall {
	display: none;
}

@media screen and (max-width: 768px) {
	.breadcrumbsMain {
		left: 0;
		width: 100vw;
		padding-right: 70px;
	}
	.breadcrumbsName {
		font-size: 16px;
	}
	.breadcrumbsLarge {
		display: none;
	}
	.breadcrumbsSmall {
		display: flex;
		align-items: center;
	}
}
