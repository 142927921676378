.onboarding_header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 122px;
    top: 0;
    height: 102px;
    width: min(calc(100vw - 170px),1296px);
    background-color: var(--medium_blue);
    z-index: 2001;
}

.onboarding_support {
    width: min(calc(100vw - 170px),1296px);
}

.outro_background {
    color: #000026;
    background: linear-gradient(159deg, rgba(255,204,200,1) 0%, rgba(188,128,240,1) 100%);
    min-height: 665px;
}

.onboarding_questionmark {
    background-color: #000026;
    border: 1px solid #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.onboarding_progressbar {
    width: calc(100% - 7px);
    height: 6px;
    border-radius: 5px;
    background-color: #4C4C67;
}

.onboarding_progressbar_progress {
    height: 6px;
    border-radius: 5px;
    background-color: #bc80f0;
}

.onboarding_progress_dot {
    position: absolute;
    height: 26px;
    width: 26px;
    background-color: var(--medium_blue);
    border: 6px solid #4C4C67;
    border-radius: 50%;
    top: -9px;
}

.onboarding_progress_dot.passed_progress {
    background-color: rgb(188, 128, 240);
    background-image: url('/assets/icon/dark_blue/check.svg');
    border-color:rgb(188, 128, 240);
    background-size: contain;
}

.onboarding_logo {
    height: 27px;
    width: 156px;
    background-image: url(/assets/icon/green/logo_text.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.onboarding_img_mobile {
    max-height: 391px;
    width: 100%;
    margin: 0 auto;
}

.onboarding_intro_bar {
    width: 100%;
    background-color: #fff;
    height: 1px;
    margin: 12px 0;
}

.onboarding_intro_bar.not_reached {
    opacity: 0.4;
}

.not_reached {
    opacity: 0.4;
}

.coach_select_section {
    background-image: url('/assets/animations/coach_star.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 50px;
}

@media screen and (max-width: 768px) {
    .onboarding_header {
        left: 0;
        width: 100vw;
        padding: 0 16px;
        height: 68px;
        background-color: var(--dark_blue);
    }
    .onboarding_support {
    width: min(calc(100vw - 30px),1296px);
    }
    .onboarding_img_mobile {
        max-height: 215px;
    }
    .onboarding_logo {
        width: 27px;
    }

    .onboarding_progressbar {
        height: 4px;;
    }

    .onboarding_progressbar_progress {
        height: 4px;
    }    
}
