.flex-container {
    display: flex;
    flex-direction: row;
  }

.ai-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    width: 20vw;


}
.ai-container-img {
    width: 20vw;
}
  
  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 800px) {
    .flex-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .ai-container {
    display: flex;
      padding-top: 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-left: 0;
    }

    .ai-container-img {
        width: 100%;
        max-height: 200px;
    }
  }