.carousel_swipe_container {
    position: relative;
    width: 100%;
    height: 523px;
}

.carousel_swipe_area {
    position: absolute;
    z-index: 3000;
    height: 523px;
    cursor: pointer;
}

.carousel_card {
    width: 392px;
    height: 523px;
    position: absolute;
    left: calc(50% - 196px);
    color: #fff;
    background-color: var(--dark_blue);
    border-radius: 5px;
    padding: 40px;
}

.carousel_arrow_left {
    position: absolute;
    top: 240px;
    left: 100px;
}

.carousel_arrow_right {
    position: absolute;
    top: 240px;
    right: 100px;
}

.single_area_question_card {
    background-color: var(--dark_blue);
    width: 392px;
    height: 523px;
    border-radius: 5px;
    padding: 40px;
}

.carousel_lock {
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF405E;
    cursor: pointer;
    top: -25px;
    right: -25px;
}

@media only screen and (max-width: 768px) {
    .carousel_card {
        width: 302px;
        left: calc(50% - 150px);
        height: 453px;
        padding: 15px;
    }

    .carousel_arrow_left {
     left: 1px;
    }

    .carousel_arrow_right {
        right: 1px;
    }

    .single_area_question_card {
        width: 310px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 769px) {
    .task_cards_full {
        margin-left: -45px;
    }

    .single_area_question_card {
        width: 392px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1200px) {
    
    .single_area_question_card {
        width: 340px;
        margin: unset
    }

    .task_cards_full {
        min-width: 1036px;
    }

}

@media screen and (min-width: 1385px) {
    
    .single_area_question_card {
        width: 392px;
        margin-bottom: 20px;
    }

    .task_cards_full {
        min-width: 1234px;
    }
}

@media screen and (min-width: 1470px) {
    .task_cards_full {
        min-width: 1356px;
    }
}