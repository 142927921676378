.input-description {
  background-color: var(--blue);
  padding: 10px;
  border-radius: 3px;
  max-width: 250px;
  position: absolute;
  z-index: 1000;
}

.radioselect_box {
	height: 24px;
	min-width: 24px;
	border: 2px solid #fff;
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
	box-sizing: border-box;
}

.radioselect_box.selected {
	border: 2px solid #000026;
	background-color: #D1F19E;
	box-shadow:
  0 0 0 1px #D1F19E;
}