.disa_name {
    display: inline-block;
    width: 110px;
    height: 24px;
}
.disa_bar {
    display: inline-block;
    width: calc(40vw - 140px);
    height: 24px;
    padding-top: 12px;
}
.disa_value {
    display: inline-block;
    padding-left: 8px;
}
