.segment-button-checked {
    --indicator-color: #fff;
}

.dark_blue.segment-button-checked {
  --indicator-color: #000026;
}

.background_stripes {
    background-image: url('/assets/illustration/blue_stripes.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    min-height: 695px;
}

.background_stripes.dark {
    background-image: url('/assets/illustration/dark_blue_stripes.svg');
    background-color: #E8EFFF;
    color: #000026;
}

.login_logo {
    position: absolute;
    left: 0px;
    top: -65px;
}

.bg_color_blue {
    color: #000026;
}

.page_input.bg_color_blue {
    border: 1px solid #000026;
	color: #000026;
}

.page_section.bg_color_blue {
    background-color: #E8EFFF;
    color: #000026;
}

.login_inputs {
    max-width: 495px;
    width: 100%;
}

.login_segment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
    padding: 0 60px;
    margin: 55px 0 60px 0;
}

.login_agreement {
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
}

.login_agreement.checked {
    background-image: url('/assets/icon/white/checkmark.svg');
    background-repeat: no-repeat;
}

.date_input.bg_color_blue {
	background-color: #E8EFFF;
    color: #000026;
	border: 1px solid #000026;
}

.date_input.bg_color_blue::-webkit-calendar-picker-indicator {
  background-image: url('/assets/icon/calendar.svg');
}

@media screen and (max-width: 768px) {
    .login_progressbar {
        width: calc(100vw - 270px);
    }

    .login_segment {
        padding: 0 10px;
    }
}

@media screen and (min-width: 769px) {
    .login_progressbar {
        width: calc(100vw - 400px);
        max-width: 812px;
    }    
}
